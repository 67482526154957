import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { theme } from "../../../utils/global-styles/theme";

const StyledButton = ({ text, handleClick, styles, disabled }) => {
  return (
    <button
      css={css`
        ${styles}
      `}
      onClick={handleClick}
      onMouseDown={e => e.preventDefault()}
      disabled={disabled || false}
      data-testId="styled-button"
    >
      {text}
    </button>
  );
};

StyledButton.propTypes = {
  text: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  classNames: PropTypes.string,
};

export default StyledButton;
