import React from "react";
import { css } from "@emotion/core";
import { theme } from "../../../utils/global-styles/theme";
import PropTypes from "prop-types";

const RadioButton = ({
  checked,
  onChange,
  disabled,
  type,
  item,
  checkboxBorderColor,
}) => {
  return (
    <ul
      css={css`
        li,
        p {
          font-family: ${theme.fontFaces.headers};
        }
        li {
          position: relative;
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 23px;
          p {
            color: ${theme.colors.main};
          }
          svg {
            margin-right: 0.5em;
          }
        }
        li > label
        {
          font-size :13px;
          @media only screen and (max-width: ${theme.breakpoints.mobile}) {
            font-size :12px;
          }
        }

        input[type="radio"] {
          opacity: 0;
          cursor: pointer;
          margin-right: 1.5em;
        }
        label > span {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 24px;
          width: 24px;
          background-color: transparent;
          border-radius: 50%;
          border: 2px solid #b4b4b4;
          transition: all 0.3s ease-out;
          -webkit-transition: all 0.3s ease-out;
          -ms-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
        }
        label span:after {
          position: absolute;
          content: "";
          left: 12px;
          top: 12px;
          height: 0px;
          width: 0px;
          border-radius: 5px;
          border-width: 2px;
    border-style: solid;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(0deg) scale(0);
          -ms-transform: rotate(0deg) scale(0);
          transform: rotate(0deg) scale(0);
          -webkit-transition: all 0.3s ease-out;
          -ms-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
          opacity: 1;
        }
        label input:radio ~ span {
          border-radius: 50%
          -webkit-transform: rotate(0deg) scale(1);
          -ms-transform: rotate(0deg) scale(1);
          transform: rotate(0deg) scale(1);
          opacity: 1;
          border-width: 2px;
          border-style: solid;
        }

        label input:checked ~ span:after {
          -webkit-transform: rotate(45deg) scale(1);
          -ms-transform: rotate(45deg) scale(1);
          transform: rotate(45deg) scale(1);
          opacity: 1;
          left: 1px;
          top: 1px;
          width: 18px;
          height: 18px;
          border-radius: 50%;
        }
        label input:disabled ~ span:after,
      `}
    >
      <li className={disabled ? "disabled-text" : "primary"}>
        <label className={type || "" === "survey" ? "dark-gray-text" : ""}>
          <input
            name={item.name || item.label}
            checked={checked}
            type="radio"
            onChange={onChange}
            data-testId="radio-button"
            disabled={disabled}
          />
          <span
            className={checkboxBorderColor ? checkboxBorderColor : ""}
          ></span>
          {item.label}
        </label>
      </li>
    </ul>
  );
};
RadioButton.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.shape({
      icon: PropTypes.string,
    }),
    description: PropTypes.shape({
      description: PropTypes.string,
    }),

    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    checkboxBorderColor: PropTypes.string,
    type :PropTypes.string,
  }),
};

export default RadioButton;
