import React, { useEffect, useState } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { theme } from "../../utils/global-styles/theme";
import Checkbox from "../shared/Checkbox/Checkbox";
import RadioButton from "../shared/RadioButton/RadioButton";
import InputTextField from "../shared/DynamicForm/Input/InputTextField";

const StyledSurveyQuestionListWrapper = styled("div")`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-transform: capitalize;
    width: 100%;
    margin-top: 50px;
    justify-content: flex-start;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      margin-top: 20px;
    }
  `,
  StyledSurveyQuestionListTitle = styled("h4")`
    font-size: 24px;
    line-height: 30px;
    left: 0;
    top: -0.95em;
    font-family: ${theme.fontFaces.inputPlaceholders};
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 20px;
      line-height: 24px;
    }
  `,
  StyledSurveyInstruction = styled("p")`
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
    font-family: ${theme.fontFaces.inputPlaceholders};
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 13px;
      line-height: 16px;
    }
  `,
  StyledSurveyOutsideOptionsWrapper = styled("div")`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  StyledSurveyOptionsWrapper = styled("div")`
    width: 50%;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      width: 100%;
    }
  `,
  CheckboxWrapper = styled("div")`
    width: 100%;
  `,
  SurveyFormLabelStyles = css`
    font-weight: bold;
    font-family: ${theme.fontFaces.inputPlaceholders};
    position: relative;
    top: -0.75em;
    background-color: white;
    padding: 0 8px;
    left: 4px;
    font-size: 13px;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      font-size: 12px;
    }
  `,
  SurveyFormWrapperStyles = css`
    border: 2px solid ${theme.colors.accent};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 16px 12px;
    margin: 15px 0;
  `,
  HelpTextStyles = css`
    font-size: 12px;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      display: none;
    }
  `,
  formStyles = {
    labelStyles: SurveyFormLabelStyles,
    valueStyles: css`
      font-size: 18px;
    `,
    wrapperStyles: SurveyFormWrapperStyles,
    labelClassNames: "dark-gray-text",
    helpTextStyles: HelpTextStyles,
  };
let answers = {
  accomplish: { selected: [], other: "" },
  current_role: "",
  current_sector: "",
  area_of_interest: { selected: [], other: "" },
};
function SurveyList({ identifier, survey, setAnswers }) {
  const [checkedItems, setCheckUncheck] = useState({}),
    [otherValue, setOtherValue] = useState(""),
    questionSize = survey.options.length,
    rowPerColumn = Math.ceil(questionSize / 2),
    removeItem = item => {
      const index = answers[identifier]["selected"].indexOf(item);
      if (index > -1) {
        answers[identifier]["selected"].splice(index, 1);
      }
    },
    handleChange = event => {
      const item_identifier = event.target.name,
        item_identifiers = item_identifier.split("_");
      const [item] = item_identifiers,
        value = event.target.value,
        isChecked = event.target.checked,
        type = event.target.type;
      switch (type) {
        case "text":
          setOtherValue(value);
          answers[identifier]["other"] = value;
          break;
        case "checkbox":
          isChecked
            ? answers[identifier]["selected"].push(item)
            : removeItem(item);
          setCheckUncheck({
            ...checkedItems,
            [item_identifier]: isChecked,
          });
          break;
        case "radio":
          answers[identifier] = isChecked ? item : "";
          setCheckUncheck({ [item_identifier]: isChecked });
          break;
        default:
      }

      setAnswers(answers);
    },
    formatOption = option => {
      return {
        item: {
          name: `${option}_${identifier}`,
          label: option,
        },
        disabled: false,
        checked: checkedItems[`${option}_${identifier}`] || false,
        type: "survey",
        checkboxBorderColor:
          "secondary-checkbox-border-override primary primary-checked-background",
      };
    },
    getInputField = (option, helpText) => {
      return (
        <InputTextField
          name={option}
          placeholder=""
          required={false}
          label={option}
          styles={formStyles}
          handleChange={handleChange}
          key={option}
          value={otherValue}
          type="text"
          helpText={helpText}
        />
      );
    },
    renderOptions = (survey, startIndex, endIndex) => {
      const renderedOptions = [];
      const options = survey.options;
      for (let i = startIndex; i < endIndex; i++) {
        if (survey.multipleAnswers) {
          renderedOptions.push(
            <CheckboxWrapper key={i}>
              {options[i] === "Other" ? (
                getInputField(options[i], survey.factsHighlight)
              ) : (
                <Checkbox
                  onChange={handleChange}
                  key={i}
                  {...formatOption(options[i])}
                ></Checkbox>
              )}
            </CheckboxWrapper>
          );
        } else {
          renderedOptions.push(
            <CheckboxWrapper key={i}>
              <RadioButton
                onChange={handleChange}
                key={i}
                {...formatOption(options[i])}
              ></RadioButton>
            </CheckboxWrapper>
          );
        }
      }
      return renderedOptions;
    };

  return (
    <StyledSurveyQuestionListWrapper>
      <StyledSurveyQuestionListTitle>
        {survey.title}
      </StyledSurveyQuestionListTitle>
      <StyledSurveyInstruction>
        {survey.description == null ? "" : survey.description.description}
      </StyledSurveyInstruction>
      <StyledSurveyOutsideOptionsWrapper>
        <StyledSurveyOptionsWrapper>
          {renderOptions(survey, 0, rowPerColumn)}
        </StyledSurveyOptionsWrapper>
        <StyledSurveyOptionsWrapper>
          {renderOptions(survey, rowPerColumn, questionSize)}
        </StyledSurveyOptionsWrapper>
      </StyledSurveyOutsideOptionsWrapper>
    </StyledSurveyQuestionListWrapper>
  );
}

SurveyList.propTypes = {
  survey: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.shape({
      description: PropTypes.string,
    }),

    multipleAnswers: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.string),
  }),
  setSelected: PropTypes.func,
};

export default SurveyList;
