import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { theme } from "../../../utils/global-styles/theme";

const PrimaryButton = ({
  text,
  handleClick,
  styles,
  disabled,
  classNames,
  submit,
}) => {
  return (
    <button
      className={`black-button ${classNames ? classNames : ''}`}
      css={css`
        color: ${classNames ? "" : theme.colors.white};
        border: ${classNames && classNames.includes("border") ? "" : "none"};
        font-family: ${theme.fontFaces.headers};
        &:hover {
          cursor: pointer;
        }
        &:disabled {
          background-color: ${theme.colors.accent};
          cursor: not-allowed;
          transition-timing-function: ease-in;
          transition: 0.25s;
        }
        &:enabled {
          transition-timing-function: ease-out;
          transition: 0.25s;
        }
        ${styles}
      `}
      onClick={handleClick}
      onMouseDown={e => e.preventDefault()}
      disabled={disabled || false}
      data-testId="primary-button"
      type={submit ? "submit" : ""}
    >
      {text}
    </button>
  );
};

PrimaryButton.propTypes = {
  text: PropTypes.string,
  styles: PropTypes.shape({
    toString: PropTypes.func,
   }),
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  classNames: PropTypes.string,
};

export default PrimaryButton;
