import React, { useState, useEffect } from "react";
import { theme } from "../utils/global-styles/theme";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Layout from "../components/Layout/Layout";
import PropTypes from "prop-types";
import SurveyList from "../components/OnboardingSurvey/SurveyList";
import PrimaryButton from "../components/shared/PrimaryButton/PrimaryButton";
import StyledButton from "../components/shared/DynamicForm/StyledButton";
import { _post } from "../utils/services/api.service";
import { navigate } from "gatsby";

const OuterWrapper = styled("div")`
    display: grid;
    grid-template: 120px 1fr 50px / 30% 50% 20%;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      grid-template-columns: 10px 1fr 10px;
    }
  `,
  InteriorWrapper = styled("div")`
    grid-area: 2 / 2 / span 1;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      grid-area: 1 / 2 / span 2 / span 1;
    }
  `,
  Title = styled("h1")`
    font-size: 53px;
    font-weight: bold;
    letter-spacing: -1.04px;
    line-height: 64px;
    margin-bottom: 10px;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      font-size: 32px;
      letter-spacing: -0.83px;
      line-height: 38px;
      margin-top: 30px;
    }
  `,
  SubTitle = styled("h5")`
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -0.22px;
    line-height: 37px;
    font-family: ${theme.fontFaces.inputPlaceholders};
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.18px;
      line-height: 21px;
    }
  `,
  ListOuterWrapper = styled("div")`
    display: block;
  `,
  NextButtonStyles = css`
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    width: 164px;
    padding: 10px 50px;
    display: block;
    margin-top: 12px;
    font-size: 18px;
    font-weight: bolder;
    letter-spacing: 0.65px;
    font-family: ${theme.fontFaces.inputPlaceholders};
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      background-color: ${theme.colors.accent};
      cursor: not-allowed;
      transition-timing-function: ease-in;
      transition: 0.25s;
    }
    &:enabled {
      transition-timing-function: ease-out;
      transition: 0.25s;
    }
  `,
  getSurveyIdentifier = [
    "accomplish",
    "current_role",
    "current_sector",
    "area_of_interest",
  ];
let answers = {},
  setAnswers = new_answers => {
    answers = { ...new_answers };
  };
const OnboardingSurveyPage = ({
  location: { state: account },
  data: onboardingSurveyData,
  pageContext: { locale },
}) => {
  const [loading, setLoading] = useState(true),
    data = onboardingSurveyData?.onboardingSurveyData?.nodes[0],
    [userAccountDetails, setUserAccountDetails] = useState(
      account ? { ...account.account } : {}
    ),
    { title, subTitle, surveyQuestion: surveyQuestions } = data,
    callAcumenApi = async () => {
      setLoading(true);
      const response = await _post({
        endpoint: `surveys`,
        args: { ...answers, email: userAccountDetails.email },
      });
      if (response.success) {
        //redirect user to IDP login page
        navigate(`/registrationSuccess/${locale === "es" ? "es" : ""}`);
      } else {
        //Show notification
        setLoading(false);
        navigate(`/registrationSuccess/${locale === "es" ? "es" : ""}`);
      }
    },
    handleClick = () => {
      //loop through selected and merge multipleOptions with other
      for (const key in answers) {
        if (typeof answers[key] == "object" && !Array.isArray(answers[key])) {
          answers[key] =
            answers[key].other !== ""
              ? [...answers[key].selected, answers[key].other]
              : answers[key].selected;
        }
      }
      callAcumenApi();
    },
    handleSkip = () => {
      //redirect user to IDP login page
      navigate(`/registrationSuccess/${locale === "es" ? "es" : ""}`);
    };

  useEffect(() => {
    //if state is empty return user to reconcile page

    if (!account) {
      return navigate(`/register${locale === "es" ? "/es" : ""}`);
    }

    const pendingApplicationDetails = localStorage.getItem(
      "pendingApplicationDetails"
    );
    if (pendingApplicationDetails) {
      //skip onboarding survey if user has a pending application
      navigate(`/registrationSuccess/${locale === "es" ? "es" : ""}`);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Layout loading={loading} locale={locale}>
      <OuterWrapper>
        <InteriorWrapper>
          <Title>
            {title} {userAccountDetails.first_name}!
          </Title>
          <SubTitle>{subTitle}</SubTitle>
          <ListOuterWrapper>
            {surveyQuestions.map((surveyQuestion, index) => (
              <SurveyList
                key={index}
                survey={surveyQuestion}
                setAnswers={setAnswers}
                identifier={getSurveyIdentifier[index]}
              />
            ))}
          </ListOuterWrapper>
          <PrimaryButton
            text={data?.cta[0].linkText}
            handleClick={() => handleClick()}
            styles={css`
              padding: 10px 50px;
            `}
            classNames={"mkt-sup-survey-btn white"}
          />
          <StyledButton
            className="primary-border"
            text={data?.cta[1].linkText}
            styles={NextButtonStyles}
            handleClick={handleSkip}
          />
        </InteriorWrapper>
      </OuterWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    onboardingSurveyData: allContentfulMigrationAndReconciliation(
      filter: {
        queryIdentifier: { eq: "Onboarding Survey" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        title
        subTitle
        surveyQuestion {
          title
          description {
            description
          }
          factsHighlight
          options
          multipleAnswers
        }
        cta {
          linkText
        }
      }
    }
  }
`;

OnboardingSurveyPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      account: PropTypes.objectOf(PropTypes.string),
      data: PropTypes.objectOf(PropTypes.string),
    }),
  }),
};

export default OnboardingSurveyPage;
